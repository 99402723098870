<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card>
          <div class="mx-1 pt-2">
            <b class="font-large-1"> {{ currentUser.name }} </b>
          </div>

          <MyTable
            :columns="columns"
            :rows="rows"
            :is-loading="isLoading"
            :custom-button="customButton"
            :has-export="true"
            :checkbox-enabled="true"
            :exporting="isExporting"
            @customButton="handleAddLP"
            @handleExport="handleExport"
            @getLogPointLogs="getLogPointLogs"
          >
            <template v-if="isB2cUser" v-slot:calendar>
              <div class="mb-2 d-flex align-items-center">
                <DatePicker
                  v-model="dateRange"
                  type="month"
                  format="MMMM YYYY"
                  placeholder="Select a month"
                  :range="true"
                  :editable="false"
                  :confirm="true"
                  :disabled="isUpdatingTable"
                  @change="handleDateChange()"
                  @clear="handleDateChange()"
                />

                <div class="ml-1">
                  <b-button
                    v-if="isUpdatingTable || isExportingSOA || !dateRange[0]"
                    disabled
                    class="d-flex"
                    variant="gradient-primary"
                    block
                    style="width: 175px"
                  >
                    Generate SOA
                    <feather-icon icon="DownloadIcon" class="ml-25" />
                  </b-button>
                  <b-button
                    v-else
                    class="d-flex"
                    variant="gradient-primary"
                    block
                    style="width: 175px"
                    @click="generateSOA()"
                  >
                    Generate SOA
                    <feather-icon icon="DownloadIcon" class="ml-25" />
                  </b-button>
                </div>
                <span v-if="isExportingSOA" class="ml-1"
                  >Generating SOA...</span
                >
              </div>
            </template>

            <template v-slot="{ props }">
              <span v-if="props.column.field === 'id'">
                <b-button variant="gradient-primary" block>
                  {{ !props.row.id ? "N/A" : props.row.id }}
                </b-button>
              </span>
              <span v-if="props.column.field === 'order_id'">
                <b-button variant="gradient-primary" block>
                  {{ !props.row.order_id ? "N/A" : props.row.order_id }}
                </b-button>
              </span>
              <span v-if="props.column.field === 'type'">
                <b-button
                  v-if="props.row.type === 'RESTORE'"
                  variant="gradient-success"
                  block
                >
                  {{ props.row.type }}
                </b-button>
                <b-button v-else variant="gradient-danger" block>
                  {{ !props.row.type ? "NULL" : props.row.type }}
                </b-button>
              </span>
              <div v-if="props.column.field === 'logpoints_reloaded'">
                <span v-if="props.row.type === 'RESTORE'">
                  {{
                    !props.row.logpoint
                      ? "NULL"
                      : $helpers.formatTotal(props.row.logpoint)
                  }}
                </span>
              </div>
              <div v-if="props.column.field === 'logpoints_deducted'">
                <span v-if="props.row.type === 'DEDUCT'">
                  {{
                    !props.row.logpoint
                      ? "NULL"
                      : $helpers.formatTotal(props.row.logpoint)
                  }}
                </span>
              </div>
              <div v-if="props.column.field === 'balance'">
                {{
                  !props.row.balance
                    ? "NULL"
                    : $helpers.formatTotal(props.row.balance)
                }}
              </div>
              <div v-if="props.column.field === 'comment'">
                {{ !props.row.comment ? "NULL" : props.row.comment }}
              </div>
              <div v-if="props.column.field === 'remarks'">
                {{ !props.row.remarks ? "None" : props.row.remarks }}
              </div>
              <div v-if="props.column.field === 'created_at'">
                {{
                  !props.row.created_at
                    ? "NULL"
                    : props.row.created_at.split("T")[0].substr(0, 10)
                }}
              </div>
              <div
                v-if="props.column.field === 'action'"
                class="d-flex justify-content-center"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="handleEditLP(props.row.id, props.row.originalIndex)"
                  >
                    <span>Edit Logpoint</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </MyTable>
        </b-card>
      </b-col>
    </b-row>
    <ValidationObserver ref="addLogpoint">
      <b-modal
        ref="handleAddLP"
        size="lg"
        centered
        title="ADD / REDUCE LP"
        hide-footer
        no-close-on-backdrop
      >
        <b-form>
          <b-row>
            <b-col>
              <b-form-group label="Amount LP" class="w-100">
                <b-row>
                  <b-col
                    cols="6"
                    class="pr-0 d-flex justify-content-center align-items-center"
                  >
                    <div class="d-flex justify-content-center">
                      <b-form-radio
                        v-model="addPayload.type"
                        name="Add"
                        value="RESTORE"
                        class="mr-2"
                      >
                        Add
                      </b-form-radio>
                      <b-form-radio
                        v-model="addPayload.type"
                        name="Reduce"
                        value="DEDUCT"
                      >
                        Reduce
                      </b-form-radio>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="LP Amount"
                        rules="required"
                      >
                        <input
                          v-model="addPayload.logpoint"
                          type="number"
                          class="form-control font-small-3"
                          placeholder="LP Amount"
                          aria-label="LP Amount"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Remarks" class="w-100">
                <ValidationProvider
                  #default="{ errors }"
                  name="Remarks"
                  class="w-100"
                  rules="required"
                >
                  <input
                    v-model="addPayload.remarks"
                    type="text"
                    class="form-control font-small-3"
                    placeholder="Remarks"
                    aria-label="Remarks"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="my-2 d-flex justify-content-center">
            <b-button
              variant="gradient-primary"
              type="submit"
              :disabled="isLoading"
              @click.prevent="addOrReduceLP()"
            >
              SUBMIT
            </b-button>
          </b-row>
        </b-form>
      </b-modal>
    </ValidationObserver>
    <ValidationObserver ref="editLogpoint">
      <b-modal
        ref="handleEditLP"
        size="lg"
        centered
        title="EDIT LOGPOINT"
        hide-footer
        no-close-on-backdrop
      >
        <b-form>
          <b-row>
            <b-col>
              <b-form-group label="Amount LP" class="w-100">
                <b-row>
                  <b-col
                    cols="6"
                    class="pr-0 d-flex justify-content-center align-items-center"
                  >
                    <div class="d-flex justify-content-center">
                      <b-form-radio
                        v-model="editPayload.type"
                        name="Add"
                        value="RESTORE"
                        class="mr-2"
                      >
                        Add
                      </b-form-radio>
                      <b-form-radio
                        v-model="editPayload.type"
                        name="Reduce"
                        value="DEDUCT"
                      >
                        Reduce
                      </b-form-radio>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="LP Amount"
                        rules="required"
                      >
                        <input
                          v-model="editPayload.logpoint"
                          type="number"
                          class="form-control font-small-3"
                          placeholder="LP Amount"
                          aria-label="LP Amount"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Remarks" class="w-100">
                <ValidationProvider
                  #default="{ errors }"
                  name="Remarks"
                  class="w-100"
                  rules="required"
                >
                  <input
                    v-model="editPayload.remarks"
                    type="text"
                    class="form-control font-small-3"
                    placeholder="Remarks"
                    aria-label="Remarks"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="my-2 d-flex justify-content-center">
            <b-button
              variant="gradient-primary"
              type="submit"
              @click.prevent="editLogPoint()"
            >
              SUBMIT
            </b-button>
          </b-row>
        </b-form>
      </b-modal>
    </ValidationObserver>
  </b-container>
</template>

<script>
import MyTable from "@/views/components/LogPointsDetailsTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import exportFromJSON from "export-from-json";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import jwtDefaultConfig from "@/@core/auth/jwt/jwtDefaultConfig";

export default {
  components: {
    DatePicker,
    MyTable,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      startDate: null,
      endDate: null,
      dateRange: [null, null],
      oldDateRange: null,
      isExporting: false,
      isUpdatingTable: false,
      isLoading: true,
      currentUser: {},
      customButton: {
        label: "Add / Reduce LP",
        icon: "PlusCircleIcon",
      },
      addPayload: {
        user_id: this.$route.params.id,
        branch_id: null,
        logpoint: null,
        type: "",
        remarks: "",
      },
      editPayload: {
        id: null,
        logpoint: null,
        type: "",
        remarks: "",
      },
      columns: [
        {
          label: "Transaction ID",
          field: "id",
          tdClass: "text-center align-middle",
        },
        {
          label: "Order ID",
          field: "order_id",
          tdClass: "text-center align-middle",
        },
        {
          label: "Type",
          field: "type",
          tdClass: "text-center align-middle",
        },
        {
          label: "Logpoints Reloaded",
          field: "logpoints_reloaded",
          tdClass: "text-center align-middle",
        },
        {
          label: "Logpoints Deducted",
          field: "logpoints_deducted",
          tdClass: "text-center align-middle",
        },
        {
          label: "Remaining Logpoints",
          field: "balance",
          tdClass: "text-center align-middle",
        },
        {
          label: "Comment",
          field: "comment",
          tdClass: "text-center align-middle",
        },
        {
          label: "Remarks",
          field: "remarks",
          tdClass: "text-center align-middle",
        },
        {
          label: "Created At",
          field: "created_at",
          tdClass: "text-center align-middle",
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [],
      required,
      isB2cUser: false,
      isExportingSOA: false,
    };
  },

  created() {
    this.getLogPointLogs();
  },
  methods: {
    getLogPointLogs() {
      this.isLoading = true;
      this.$http
        .get(
          `get_logpoint_logs?user_id=${this.$route.params.id}${
            this.dateRange[0]
              ? `&startDate=${this.startDate}&endDate=${this.endDate}`
              : ""
          }`
        )
        .then((response) => {
          if (response.data.status) {
            this.rows = response.data.logs.filter((log) => log.del_flag !== 1);
            this.currentUser = response.data.user;
            if (this.currentUser.type === 3) {
              this.isB2cUser = true;
            }
            this.addPayload.branch_id = this.currentUser.branch_id;
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
          this.isLoading = false;
          this.isUpdatingTable = false;
        });
    },

    handleAddLP() {
      this.$refs.handleAddLP.show();
    },

    addOrReduceLP() {
      this.$refs.addLogpoint.validate().then((success) => {
        if (success) {
          if (this.addPayload.type != "DEDUCT") {
            this.isLoading = true;
            const payload = new FormData();

            for (const key in this.addPayload) {
              payload.append(key, this.addPayload[key]);
            }

            this.$http.post("addOrDeduct", payload).then((response) => {
              if (response.data.status) {
                this.$swal({
                  title: "Success !",
                  icon: "success",
                  text: response.data.message,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.getLogPointLogs();
                    this.$refs.handleAddLP.hide();
                  }
                });
              } else if (response.data.errors) {
                this.$refs.addLogpoint.setErrors(response.data.errors);
              } else {
                this.$swal({
                  title: "Error !",
                  icon: "warning",
                  text: response.data.message,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.go(0);
                  }
                });
              }
              this.isLoading = false;
            });
          } else if (
            this.addPayload.type == "DEDUCT" &&
            this.rows[0].balance > this.addPayload.logpoint
          ) {
            this.isLoading = true;
            const payload = new FormData();

            for (const key in this.addPayload) {
              payload.append(key, this.addPayload[key]);
            }

            this.$http.post("addOrDeduct", payload).then((response) => {
              if (response.data.status) {
                this.$swal({
                  title: "Success !",
                  icon: "success",
                  text: response.data.message,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.getLogPointLogs();
                    this.$refs.handleAddLP.hide();
                  }
                });
              } else if (response.data.errors) {
                this.$refs.addLogpoint.setErrors(response.data.errors);
              } else {
                this.$swal({
                  title: "Error !",
                  icon: "warning",
                  text: response.data.message,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.go(0);
                  }
                });
              }
              this.isLoading = false;
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Logpoint cannot be negative!",
                icon: "XIcon",
                variant: "warning",
              },
            });
          }
        }
      });
    },

    handleEditLP(id, index) {
      const row = this.rows[index];
      this.editPayload = {
        id,
        logpoint: row.logpoint,
        type: row.type,
        remarks: row.remarks,
      };
      this.$refs.handleEditLP.show();
    },

    editLogPoint() {
      this.$refs.editLogpoint.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          const payload = new FormData();

          for (const key in this.editPayload) {
            payload.append(key, this.editPayload[key]);
          }

          this.$http.post("edit_logpoint", payload).then((response) => {
            if (response.data.status) {
              this.$swal({
                title: "Success !",
                icon: "success",
                text: response.data.message,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.getLogPointLogs();
                  this.$refs.handleEditLP.hide();
                }
              });
            } else if (response.data.errors) {
              this.$refs.editLogpoint.setErrors(response.data.errors);
            } else {
              this.$swal({
                title: "Error !",
                icon: "warning",
                text: response.data.message,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.go(0);
                }
              });
            }
            this.isLoading = false;
          });
        }
      });
    },

    async handleExport(type) {
      this.isExporting = true;
      const fileName = "generated-user-logpointdetails";
      const data = this.rows.map((item) => ({
        "Transaction ID": item.id,
        "Order ID": item.order_id ? item.order_id : "N/A",
        Type: item.type,
        "Logpoints Reloaded": item.type === "RESTORE" ? item.logpoint : "",
        "Logpoints Deducted": item.type === "DEDUCT" ? item.logpoint : "",
        "Remaining Logpoints": item.balance ? item.balance : "N/A",
        Comment: item.comment ? item.comment : "None",
        Remarks: item.remarks ? item.remarks : "None",
        "Created At": item.created_at,
      }));

      let exportType;
      switch (type) {
        case "xlsx":
          exportType = exportFromJSON.types.xls;
          exportFromJSON({ data, fileName, exportType });
          break;
        case "csv":
          exportType = exportFromJSON.types.csv;
          exportFromJSON({ data, fileName, exportType });
          break;
      }
      this.isExporting = false;
    },
    async generateSOA() {
      this.isExportingSOA = true;
      this.isUpdatingTable = true;
      await this.$http
        .get(
          `generate_soa?user_id=${this.$route.params.id}${
            this.dateRange[0]
              ? `&startDate=${this.startDate}&endDate=${this.endDate}`
              : ""
          }`,
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `SOA_${this.currentUser.name}_${this.startDate}_to_${this.endDate}.pdf`;
          link.setAttribute("download", fileName); // Set the file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          this.$swal({
            title: "Error !",
            icon: "warning",
            text: error.data.message,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        });
      this.isExportingSOA = false;
      this.isUpdatingTable = false;
    },
    async handleDateChange() {
      if (this.dateRange) {
        this.startDate = moment(this.dateRange[0]).format("YYYY-MM");
        this.endDate = moment(this.dateRange[1]).format("YYYY-MM");
        const newDate = [this.startDate, this.endDate];
        if (
          !this.oldDateRange ||
          (this.oldDateRange &&
            (this.oldDateRange[0] !== this.startDate ||
              this.oldDateRange[1] !== this.endDate))
        ) {
          this.isUpdatingTable = true;

          this.oldDateRange = newDate;
          await this.getLogPointLogs();
        }
      }
    },
  },
};
</script>
